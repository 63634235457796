<script lang="ts" setup>
const props = defineProps<{
  visible: boolean;
  contentClass?: string;
  fixedDuration?: number;
  destructible?: boolean;
}>();
defineSlots<{
  default: () => VNode[];
}>();

const content = ref<HTMLDivElement>();
const height = ref(0);

const duration = computed(() => props.fixedDuration || height.value);
const gridTransitionStyle = computed(
  () => `grid-template-rows ${duration.value}ms ease`,
);
const opacityTransitionStyle = computed(
  () => `opacity ${duration.value}ms ease, height ${duration.value}ms ease`,
);

const onStartAnimation = () => {
  nextTick(() => {
    if (content.value?.scrollHeight)
      height.value = content.value?.scrollHeight || 0;
  });
};
</script>

<template>
  <Transition
    name="collapse-transition"
    :duration="duration"
    @before-enter="onStartAnimation"
    @before-leave="onStartAnimation"
  >
    <div v-if="!destructible || visible" v-show="visible" class="collapse">
      <div ref="content" class="collapse__content" :class="contentClass">
        <slot />
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
.collapse {
  $self: &;

  display: grid;
  grid-template-rows: 1fr;
  transition: v-bind(gridTransitionStyle);

  &__content {
    overflow: hidden;
    transition: v-bind(opacityTransitionStyle);
  }

  &.collapse-transition-enter-from,
  &.collapse-transition-leave-to {
    grid-template-rows: 0fr;

    #{$self}__content {
      opacity: 0;
    }
  }
}
</style>
